<template lang="">
    <div>
        <Show/>
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Yer Gösterim Listesi", route: "/lists/show" },
    ]);
  },
    components: {
    Show: () => import('@/components/lists/Show.vue'),
}
}
</script>
<style lang="">
    
</style>